<template>
	<v-fade-transition hide-on-leave>
		<template v-if="loading || loadingParent">
			<v-skeleton-loader key="transitionCard" type="card" class="rounded-xl" />
		</template>
		<template v-else>
			<v-card key="transitionEnded" class="mx-auto flex-grow-1 flex-shrink-0 d-flex flex-column" style="width: 100%" rounded="xl">
				<v-card-title>
					<span>{{ $t('cv.projects') }}</span>
				</v-card-title>
				<v-card-text>
					<template v-for="project in projects">
						<v-card class="my-2" flat :key="`project${project.id}`" rounded="xl">
							<v-card-title>
								{{ project.name }}
								<v-btn icon color="accent" :href="project.website" v-if="project.website" target="_blank">
									<v-icon>mdi-open-in-new</v-icon>
								</v-btn>
								<v-spacer />
								<v-btn text color="secondary" @click="callRemoveProject(project)">
									{{ $t('settings.delete') }}
								</v-btn>
							</v-card-title>
							<v-card-subtitle>
								{{ formatDate(project.timestamp.start) }} -
								{{ project.timestamp.end ? formatDate(project.timestamp.end) : $t('cv.present').toLowerCase() }}
							</v-card-subtitle>
							<v-card-text>{{ project.description }}</v-card-text>
						</v-card>
						<v-divider :key="`divider${project.id}`" />
					</template>
				</v-card-text>
			</v-card>
		</template>
	</v-fade-transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'CVProjects',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false
		}
	},
	computed: {
		...mapGetters({
			user: 'users/user',
			projects: 'cv/projects'
		})
	},
	watch: {
		user(val) {
			this.loading = true
			this.fetchProjects({ userID: val.id }).then(() => {
				this.loading = false
			})
		}
	},
	created() {
		if (this.user) {
			this.loading = true
			this.fetchProjects({ userID: this.user.id }).then(() => {
				this.loading = false
			})
		}
	},
	methods: {
		formatDate(date) {
			return this.$moment
				.utc(date)
				.locale(this.$i18n.locale)
				.format('MMM YYYY')
		},
		...mapActions('cv', ['fetchProjects'])
	}
}
</script>
